import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import SermonCard from '../components/sermonCard'
import Col from 'react-bootstrap/Col'
import image from '../assets/img/intro.jpg'




const SermonsPage = () => {
  const data = useStaticQuery(sermonQuery)

  return (
    <>
      <Layout title={'Sermons'} subtitle={'HRBC'}>
        <section id="sermons" className="page-section cta">
          <Container>
            <Row>
              <Col xl={9} className="mx-auto">

                {/* <div className="avatar-checkboxes">
                  <input id="chk_dave" type="checkbox" name="wpuf_post_tags[]" value="Aliens" />
                  <label for="chk_dave" tool-tips="xcvb">
                    <div className="avatar-container" tool-tips="asdf">
                      <div className="avatar" style={{ backgroundImage: `url(${image})` }} tool-tips="qwer"></div>
                    </div>
                  </label>

                  <input id="chk_rob" type="checkbox" name="wpuf_post_tags[]" value="Aliens" />
                  <label for="chk_rob">
                    <div className="avatar-container">
                      <div className="avatar" style={{ backgroundImage: `url(${image})` }}></div>
                    </div>
                  </label>

                </div> */}

                <div className="cta-inner text-center rounded">
                  <h2 className="section-heading mb-5">
                    <span className="section-heading-upper">All</span>
                    <span className="section-heading-lower">Sermons</span>
                  </h2>
                  {data.allStrapiSermon.nodes.map((data, index) => (
                    <SermonCard key={index} sermon={data} />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default SermonsPage



const sermonQuery = graphql`{
  allStrapiSermon(sort: {fields: date, order: DESC}) {
    nodes {
      title
      subtitle
      slug
      series
      date(formatString: "YYYY-MM-DD")
      person {
        name {
          first_name
          last_name
          prefix
        }
      }
      audio {
        publicURL
      }
    }
  }
}
`
