import React from 'react'
import { Link } from 'gatsby'
import image from '../assets/img/intro.jpg'
import Moment from 'react-moment'


const SermonCard = (data) => {
  let slug = `/sermons/${data.sermon.slug}`
  let fullName = !data.sermon.person ? `unknown` : `${data.sermon.person.name.prefix} ${data.sermon.person.name.first_name} ${data.sermon.person.name.last_name}`

  return (
    <div className="row justify-content-left sermon-download-container">
      <Link to={slug} className="col-md-2 sermon-download-img" style={{ backgroundImage: `url(${image})`, minHeight: '100%' }}>
        <div className="sermon-download-icon"></div>
      </Link>
      <Link to={slug} className="col-md-8 sermon-download-info">
        <div className="date light">
          <Moment parse="YYYY-MM-DD" format="MMMM Do YYYY">{data.sermon.date}</Moment>
        </div>
        <div className="title" style={{ display: 'inline-block' }}>
          <h5 className="section-heading text-truncate" style={{ width: '100%' }}>{data.sermon.title}</h5>
        </div>
        <div className="series light">[{data.sermon.subtitle}]</div>
        <div className="speaker neutral">Sermon by {fullName}</div>
      </Link>
      <a className="col-md-2 sermon-download-btn" href={data.sermon.audio?.publicURL} download="The Lord Our Peace-HRBC">
        <div className="sermon-download-icon">
          <i className="fa fa-cloud-download" style={{ color: '#33383a' }}></i>
          <h6>Download</h6>
        </div>
      </a>
    </div>
  )
}

export default SermonCard
